import { Typography } from "@mui/material";
import { metersToKm } from "libraries/emission";
import { tripEmission } from "libraries/emission";
import TripEmissionChipTree from "components/trips/TripEmissionChipTree";
import TripEmissionChipTravel from "components/trips/TripEmissionChipTravel";
import { TripState } from "types/user";
import { CarState } from "types/cars";
import { TravelMode } from "types/map";
import { useAppSelector } from "redux/hooks";
import { selectTripTravelMode } from "redux/slices/shed/shedSlice";

type Props = {
  trip: TripState;
  tripCar: CarState | null;
};

const formatTripKM = ({ distanceMeters, commuteFrequency }: TripState) =>
  `${Math.round(metersToKm(distanceMeters * commuteFrequency))} KM`;

const formatTripCO2 = (trip: TripState, tripCar: CarState | null) => {
  const { totalKgEmission } = tripEmission(tripCar, trip.distanceMeters);
  return `${(totalKgEmission * trip.commuteFrequency).toFixed(1)} KG CO2`;
};

const TripEmissionDetails = ({ trip, tripCar }: Props) => {
  const tripTravelMode = useAppSelector(selectTripTravelMode);

  const tripKM = formatTripKM(trip);
  const tripCO2 = formatTripCO2(trip, tripCar);

  return tripTravelMode === TravelMode.WALKING ? (
    <Typography variant="body2" component="div">
      <TripEmissionChipTravel travelMode={tripTravelMode} trip={trip} />
      walk(s) ({tripKM}) saves the environment for {tripCO2} compared to using a
      car. By walking you save CO2 equivalent being the same as{" "}
      <TripEmissionChipTree trip={trip} tripCar={tripCar} />
      rainforest trees absorb yearly
    </Typography>
  ) : tripTravelMode === TravelMode.BICYCLING ? (
    <Typography variant="body2" component="div">
      <TripEmissionChipTravel travelMode={tripTravelMode} trip={trip} />
      ride(s) ({tripKM}) saves the environment for {tripCO2} compared to using
      your own car. By biking you save CO2 equivalent being the same as
      <TripEmissionChipTree trip={trip} tripCar={tripCar} />
      rainforest trees absorb yearly
    </Typography>
  ) : tripTravelMode === TravelMode.DIGITAL ? (
    <Typography variant="body2" component="div">
      <TripEmissionChipTravel travelMode={tripTravelMode} trip={trip} />
      digital event(s) allow you to travel ({tripKM}) less and saves the
      environment for {tripCO2} compared to using a car. By doing a digital
      event you save CO2 equivalent being the same as
      <TripEmissionChipTree trip={trip} tripCar={tripCar} />
      rainforest trees absorb yearly
    </Typography>
  ) : (
    <Typography variant="body2" component="div">
      <TripEmissionChipTravel travelMode={tripTravelMode} trip={trip} />
      trip(s) ({tripKM}) emit {tripCO2}, being the same as what
      <TripEmissionChipTree trip={trip} tripCar={tripCar} />
      rainforest trees absorb yearly
    </Typography>
  );
};

export default TripEmissionDetails;
