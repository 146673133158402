import { Typography } from "@mui/material";

const ProfileDashboardInDevelopment = () => (
  <>
    <Typography variant="h6" gutterBottom>
      Your statistics will come here soon.
    </Typography>
    <Typography variant="h6" gutterBottom>
      This page is currently under development
    </Typography>
  </>
);

export default ProfileDashboardInDevelopment;
