import { Chip } from "@mui/material";
import TripTravelModeIcon from "components/trips/TripTravelModeIcon";
import { TripState } from "types/user";
import { TravelMode } from "types/map";

type Props = {
  trip: TripState;
  travelMode: TravelMode;
};

const TripEmissionChipTravel = ({ trip, travelMode }: Props) => (
  <Chip
    size="small"
    variant="outlined"
    sx={{ pl: 0.5, mx: 0.25, alignItems: "flex-end" }}
    icon={<TripTravelModeIcon fontSize="small" travelMode={travelMode} />}
    label={trip.commuteFrequency}
  />
);

export default TripEmissionChipTravel;
