import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import LaptopIconIcon from "@mui/icons-material/Laptop";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import { TravelMode } from "types/map";

type Props = {
  fontSize?: "medium" | "small";
  travelMode: TravelMode;
};

const TripTravelModeIcon = ({ fontSize = "medium", travelMode }: Props) => {
  return travelMode === TravelMode.WALKING ? (
    <DirectionsRunIcon fontSize={fontSize} />
  ) : travelMode === TravelMode.BICYCLING ? (
    <DirectionsBikeIcon fontSize={fontSize} />
  ) : travelMode === TravelMode.DIGITAL ? (
    <LaptopIconIcon fontSize={fontSize} />
  ) : travelMode === TravelMode.DRIVING ? (
    <DirectionsCarIcon fontSize={fontSize} />
  ) : null;
};

export default TripTravelModeIcon;
