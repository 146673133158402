import { SyntheticEvent, useCallback } from "react";
import { ButtonBase, Button, Stack } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useAppSelector } from "redux/hooks";
import { selectReferenceCardMarker } from "redux/slices/shed/shedSlice";
import { mapNavigateToCard, getMarkerCardImageSrc } from "libraries/map";

const MapCardReference = () => {
  const cardMarker = useAppSelector(selectReferenceCardMarker);

  const handleNavigateToCard = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      cardMarker && mapNavigateToCard(cardMarker);
    },
    [cardMarker]
  );

  return cardMarker ? (
    <Stack>
      <Button
        onClick={handleNavigateToCard}
        startIcon={<SearchIcon />}
        color="secondary"
      >
        View Card
      </Button>
      <ButtonBase
        color="secondary"
        title={cardMarker.name}
        onClick={handleNavigateToCard}
      >
        <img
          style={{ borderRadius: 8, maxWidth: "100%" }}
          src={getMarkerCardImageSrc(cardMarker)}
          alt={cardMarker.name}
        />
      </ButtonBase>
    </Stack>
  ) : null;
};

export default MapCardReference;
