import {
  EXPERIENCE_ID,
  MapExperience,
  MapCardMarker,
  MapIconMarker,
  MapLocation,
  MapItinerary,
  GoogleDirectionResult,
} from "types/map";
import { getNeutralExperience } from "libraries/neutral";
import { flow, filter, first, get, pick } from "lodash/fp";
import experiences from "libraries/experiences.json";
import {
  MAP_MARKERS_PNG_CARDS_BASE,
  MAP_MARKERS_SVG_ICONS_BASE,
} from "libraries/svgPaths";

export const neutralMapLocation = (): MapLocation => ({
  formattedAddress: "",
  latitude: null,
  longitude: null,
});

export const hasLatLong = ({ latitude, longitude }: MapLocation) =>
  Boolean(latitude && longitude);

export const toGoogleLatLong = (
  latitude: number | null,
  longitude: number | null
) => ({
  lat: latitude ?? 0,
  lng: longitude ?? 0,
});

const selectDistance = get("[0].distance");
const selectDuration = get("[0].duration");

export const mapDirectionResultParser = (
  directionResult: GoogleDirectionResult | null
) => {
  if (!directionResult) {
    console.error("[Library] mapDirectionResultParser - result empty");
    return {
      distanceMeters: 0,
      distanceLabel: "",
      durationSeconds: 0,
      googleDirection: null,
      bounds: null,
    };
  }

  return flow(
    get("routes[0]"),
    pick(["legs", "bounds"]),
    ({ legs, bounds }): Omit<MapItinerary, "locationFrom" | "locationTo"> => ({
      distanceMeters: selectDistance(legs)?.value ?? 0,
      distanceLabel: selectDistance(legs)?.text ?? "",
      durationSeconds: selectDuration(legs)?.value ?? 0,
      googleDirection: directionResult,
      bounds,
    })
  )(directionResult);
};

export const getExperienceById = (experienceId: EXPERIENCE_ID): MapExperience =>
  flow(
    filter(["experienceId", experienceId]),
    first,
    (x: MapExperience | undefined): MapExperience => x ?? getNeutralExperience()
  )(experiences);

export const getMarkerCardImageSrc = ({ cardId }: MapCardMarker): string =>
  `${MAP_MARKERS_PNG_CARDS_BASE}${cardId}.png`;

export const getMarkerIconImageSrc = ({
  experienceId,
}: MapIconMarker): string => `${MAP_MARKERS_SVG_ICONS_BASE}${experienceId}.svg`;

export const mapNavigateToCard = ({ cardId }: MapCardMarker) => {
  const baseURL = process.env.REACT_APP_CARD_BASE_URL;

  console.log(`[Map] Navigate to card [${cardId}]`, baseURL);

  if (cardId && baseURL) {
    window.location.href = `${baseURL}${cardId}`;
  }
};
