import { useCallback, useEffect, useRef } from "react";
import { MapIconMarker } from "types/map";
import { registerNewTripFromMarker } from "redux/slices/shed/shedSlice";
import { useAppDispatch } from "redux/hooks";
import { getMarkerIconImageSrc } from "libraries/map";

type AdvancedMarkerElementProps = {
  map: google.maps.Map | null; // Map instance passed from MapContainer
  marker: MapIconMarker;
};

const MapMarkerIcon = ({ map, marker }: AdvancedMarkerElementProps) => {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );
  const contentRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  const handleMarkerClick = useCallback(() => {
    console.log("[Map] Experience Icon marker click", marker.name);
    dispatch(registerNewTripFromMarker(marker));

    // Consider adding "registerReferenceExperience"
  }, [dispatch, marker]);

  useEffect(() => {
    if (!map) return;

    // Create the HTML content for the AdvancedMarkerElement
    if (!contentRef.current) {
      contentRef.current = document.createElement("div");
      contentRef.current.innerHTML = `<img src="${getMarkerIconImageSrc(
        marker
      )}" alt="${marker.name}" />`;
    }

    // Create marker only once
    if (!markerRef.current) {
      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: {
          lat: marker.lat,
          lng: marker.lng,
        },
        content: contentRef.current,
        title: marker.name,
      });

      markerRef.current.addListener("click", handleMarkerClick);
    }

    return () => {
      // Cleanup: Remove content and marker from the map
      if (markerRef.current) {
        markerRef.current.map = null;
        markerRef.current = null;
      }
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [map]);

  return null;
};

export default MapMarkerIcon;
