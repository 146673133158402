import { useEffect, useRef } from "react";
import { MapIconMarker, MapCardMarker } from "types/map";
import MapMarkerCard from "components/map/MapMarkerCard";
import MapMarkerIcon from "components/map/MapMarkerIcon";
import { useAppSelector } from "redux/hooks";
import { selectReferenceExperience } from "redux/slices/shed/shedSlice";

type AdvancedMarkerElementProps = {
  map: google.maps.Map | null; // Map instance passed from MapContainer
};

const ZOOM_LEVEL_SINGLE_MARKER = 14;

const MapMarkersExperience = ({ map }: AdvancedMarkerElementProps) => {
  const boundsRef = useRef<google.maps.LatLngBounds | null>(null);

  const { experienceId, cardMarkers, iconMarkers } = useAppSelector(
    selectReferenceExperience
  );

  useEffect(() => {
    if (map && (cardMarkers.length > 1 || iconMarkers.length > 1)) {
      boundsRef.current = !boundsRef.current
        ? new google.maps.LatLngBounds()
        : boundsRef.current;

      // Extend the bounds to include each marker's position
      [...cardMarkers, ...iconMarkers].forEach((marker) => {
        boundsRef.current?.extend(
          new google.maps.LatLng(marker.lat, marker.lng)
        );
      });

      // Fit the map to the calculated bounds
      map.fitBounds(boundsRef.current);
    } else if (map && cardMarkers.length === 1) {
      map.setCenter({ lat: cardMarkers[0].lat, lng: cardMarkers[0].lng });
      map.setZoom(ZOOM_LEVEL_SINGLE_MARKER);
    } else if (map && iconMarkers.length === 1) {
      map.setCenter({ lat: iconMarkers[0].lat, lng: iconMarkers[0].lng });
      map.setZoom(ZOOM_LEVEL_SINGLE_MARKER);
    }
  }, [map, cardMarkers, iconMarkers]);

  return (
    <>
      {iconMarkers.map((marker: MapIconMarker, index: number) => (
        <MapMarkerIcon key={experienceId + index} map={map} marker={marker} />
      ))}
      {cardMarkers.map((marker: MapCardMarker, index: number) => (
        <MapMarkerCard key={experienceId + index} map={map} marker={marker} />
      ))}
    </>
  );
};

export default MapMarkersExperience;
