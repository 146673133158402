import { Chip } from "@mui/material";
import ParkIcon from "@mui/icons-material/Park";
import { emissionToTrees } from "libraries/emission";
import { TripState } from "types/user";
import { CarState } from "types/cars";

type Props = {
  trip: TripState;
  tripCar: CarState | null;
};

const TripEmissionChipTree = ({ trip, tripCar }: Props) => {
  const tripTreesCount = emissionToTrees({
    distanceMeters: trip.distanceMeters,
    emissionType: tripCar?.emissionType ?? "",
    gramEmission: tripCar?.emissionCO2 ?? 0,
  });

  return (
    <Chip
      size="small"
      color="success"
      variant="outlined"
      sx={{ mx: 0.25, alignItems: "flex-end" }}
      icon={<ParkIcon fontSize="small" sx={{ alignSelf: "center" }} />}
      label={(tripTreesCount * trip.commuteFrequency).toFixed(2)}
    />
  );
};

export default TripEmissionChipTree;
