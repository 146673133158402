import { useMemo } from "react";
import { Divider, Box, Collapse } from "@mui/material";
import { useAppSelector } from "redux/hooks";
import {
  selectReferenceItinerary,
  selectReferenceCar,
  selectHasReferenceTrip,
  selectReferenceTrip,
} from "redux/slices/shed/shedSlice";
import { styled } from "@mui/material";
import TripEmissionDetails from "components/trips/TripEmissionDetails";

const PANEL_DISPLAY_HEIGHT = 500;

const TripDetailContainerBox = styled(Box)(({ theme }) => ({
  maxHeight: PANEL_DISPLAY_HEIGHT,
  overflow: "hidden",
  scroll: "auto",
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1),
}));

const MapTripDetails = () => {
  const itinerary = useAppSelector(selectReferenceItinerary);
  const itineraryMeters = itinerary?.distanceMeters ?? 0;
  const referenceCar = useAppSelector(selectReferenceCar);
  const hasTrip = useAppSelector(selectHasReferenceTrip);
  const referenceTrip = useAppSelector(selectReferenceTrip);

  const displayedTrip = useMemo(
    () => ({
      ...referenceTrip,
      distanceMeters: itineraryMeters,
    }),
    [itineraryMeters, referenceTrip]
  );

  return (
    <Collapse in={hasTrip}>
      <TripDetailContainerBox display="flex" flexDirection="column" gap={1}>
        <Divider />
        <TripEmissionDetails trip={displayedTrip} tripCar={referenceCar} />
      </TripDetailContainerBox>
    </Collapse>
  );
};

export default MapTripDetails;
