import { useCallback } from "react";
import { Button, Box } from "@mui/material";
import { registerReferenceTripTravelMode } from "redux/slices/shed/shedSlice";
import { selectTripTravelMode } from "redux/slices/shed/shedSlice";
import { useAppSelector, useAppDispatch } from "redux/hooks";
import { TravelMode } from "types/map";
import TripTravelModeIcon from "components/trips/TripTravelModeIcon";

const MapSelectTravelMode = () => {
  const travelMode = useAppSelector(selectTripTravelMode);

  const dispatch = useAppDispatch();

  const changeToDriveMode = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.DRIVING)),
    [dispatch]
  );

  const changeToBikeMode = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.BICYCLING)),
    [dispatch]
  );

  const changeToWalkMode = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.WALKING)),
    [dispatch]
  );

  const changeToTransitMode = useCallback(
    () => dispatch(registerReferenceTripTravelMode(TravelMode.DIGITAL)),
    [dispatch]
  );

  return (
    <Box display="flex" justifyContent="space-around">
      <Button
        color={travelMode === TravelMode.DRIVING ? "secondary" : undefined}
        onClick={changeToDriveMode}
        startIcon={<TripTravelModeIcon travelMode={TravelMode.DRIVING} />}
      >
        Drive
      </Button>
      <Button
        color={travelMode === TravelMode.WALKING ? "secondary" : undefined}
        onClick={changeToWalkMode}
        startIcon={<TripTravelModeIcon travelMode={TravelMode.WALKING} />}
      >
        Walk
      </Button>
      <Button
        color={travelMode === TravelMode.BICYCLING ? "secondary" : undefined}
        onClick={changeToBikeMode}
        startIcon={<TripTravelModeIcon travelMode={TravelMode.BICYCLING} />}
      >
        Bike
      </Button>
      <Button
        color={travelMode === TravelMode.DIGITAL ? "secondary" : undefined}
        onClick={changeToTransitMode}
        startIcon={<TripTravelModeIcon travelMode={TravelMode.DIGITAL} />}
      >
        Digital
      </Button>
    </Box>
  );
};

export default MapSelectTravelMode;
