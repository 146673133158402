import { useState } from "react";
import { Grid } from "@mui/material";
import MapApplicationMenu from "components/map/MapApplicationMenu";
import MapPanelContainer from "components/map/MapPanelContainer";
import MapContainer from "components/map/MapContainer";
import useDirections from "hooks/maps/useDirections";
import useNavLocation from "hooks/maps/useNavLocation";
import { LatLng } from "types/map";
import { GoogleMap } from "types/map";
import MapDrawer from "components/map/MapDrawer";
import { useAppSelector } from "redux/hooks";
import { selectReferenceTrip } from "redux/slices/shed/shedSlice";
import useMapShareTripParams from "hooks/maps/useMapShareTripParams";
import CarNoEmissionPopup from "components/cars/CarNoEmissionPopup";
import MapMarkerCurrentPosition from "components/map/MapMarkerCurrentPosition";
import MapMarkersExperience from "components/map/MapMarkersExperience";

const MapPage = () => {
  useMapShareTripParams();

  const [map, setMap] = useState<GoogleMap | null>(null);
  const [centerGeo, setCenterGeo] = useState<LatLng>({ lat: 0, lng: 0 });
  const [zoomLevel, setZoomLevel] = useState(3);
  const {
    fromAddress,
    fromLatitude,
    fromLongitude,
    toAddress,
    toLatitude,
    toLongitude,
  } = useAppSelector(selectReferenceTrip);

  useDirections({
    from: {
      formattedAddress: fromAddress,
      latitude: fromLatitude,
      longitude: fromLongitude,
    },
    to: {
      formattedAddress: toAddress,
      latitude: toLatitude,
      longitude: toLongitude,
    },
  });

  useNavLocation({
    onInitCenterGeo: setCenterGeo,
    onInitZoomLevel: setZoomLevel,
  });

  return (
    <Grid container sx={{ height: "100vh" }}>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
        <MapPanelContainer
          locationFrom={{
            formattedAddress: fromAddress,
            latitude: fromLatitude,
            longitude: fromLongitude,
          }}
          locationTo={{
            formattedAddress: toAddress,
            latitude: toLatitude,
            longitude: toLongitude,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={8} lg={4} xl={9}>
        <MapContainer
          map={map}
          center={
            fromLatitude && fromLongitude
              ? {
                  lat: fromLatitude ?? 0,
                  lng: fromLongitude ?? 0,
                }
              : centerGeo
          }
          onMapInitialized={setMap}
          zoom={zoomLevel}
          disableDefaultUI
          zoomControl={false}
        >
          <>
            <CarNoEmissionPopup />
            <MapApplicationMenu />
            <MapDrawer />
            <MapMarkerCurrentPosition map={map} />
            <MapMarkersExperience map={map} />
          </>
        </MapContainer>
      </Grid>
    </Grid>
  );
};

export default MapPage;
