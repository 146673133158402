import { CarState } from "types/cars";
import { MapExperience } from "types/map";

export const getNeutralCar = (): CarState => ({
  id: "",
  licensePlate: "",
  genericVin: "SCFSMGAW",
  carBrand: "aston martin",
  carModel: "vantage",
  emissionCO2: 236,
  emissionType: "gasoline",
  nextControl: "",
  previousControl: "",
});

export const getNeutralExperience = (): MapExperience => ({
  experienceId: "shed-norway-rainforest-foundation",
  valueImpact: 0,
  iconMarkers: [],
  cardMarkers: [
    {
      experienceId: "shed-norway-rainforest-foundation",
      lat: 59.9167134,
      lng: 10.7479358,
      name: "Headquaters NO",
      circleRadius: 0,
      cardId: "ef9abd47-c724-428b-832e-1099ce9a40fe",
    },
  ],
});
